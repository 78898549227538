// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import LogRocket from "logrocket"
import setupLogRocketReact from "logrocket-react"
import "./src/scss/style.scss"

export function onClientEntry() {
  LogRocket.init("qv4xmc/blogbelmontrunnerscom")
  setupLogRocketReact(LogRocket)
}
